import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'antd';

type Props = {
  downloadable: 0 | 1;
  watermarked: 0 | 1;
  children: React.ReactNode;
};

const ListItemWithBadge = ({ downloadable, watermarked, children }: Props) => {
  const { t } = useTranslation('dataroom');

  const badgeText = useMemo(() => {
    const parts: string[] = [];

    if (downloadable) {
      parts.push(t('downloadable'));
    }

    if (watermarked) {
      parts.push(t('watermarked'));
    }

    return parts.join(' / ');
  }, [t, downloadable, watermarked]);

  return downloadable || watermarked ? <Badge.Ribbon text={badgeText}>{children}</Badge.Ribbon> : <>{children}</>;
};

export default ListItemWithBadge;
